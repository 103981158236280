<template>
  <footer class="mt-12">
    <div class="bg-neutral-50 mt-5">
      <div class="container 2xl:max-w-screen-2xl mx-auto pb-8 pt-8 px-6">
        <div class="grid lg:grid-cols-2 xl:grid-cols-5 gap-y-12 xl:gap-x-6">
          <div v-for="link in headerFooterLinks?.footer?.link_list" :key="link.title">
            <div v-if="link.title" class="text-xl font-semibold text-left">
              {{ link.title }}
            </div>
            <ul v-if="link.items" class="text-left">
              <li v-for="item in link.items" :key="item" class="mt-4">
                <UiMaskedLink role="link" tabindex="0" :href="item.url">
                  {{ item.title }}
                </UiMaskedLink>
              </li>
            </ul>
          </div>
          <div class="lg:col-span-2 xl:col-span-1">
            <div class="text-xl font-semibold text-left">
              {{ t('follow_us') }}
            </div>
            <div class="text-left">
              <template v-for="social_network in headerFooterLinks?.footer?.social_networks" :key="social_network.app">
                <UiMaskedLink
                  tag="span"
                  role="link"
                  tabindex="0"
                  :aria-label="t('social_network', { network: social_network.title ?? socialDefault[social_network.app].title } )"
                  :title="social_network.title ?? socialDefault[social_network.app].title"
                  :href="social_network.url ?? socialDefault[social_network.app].url"
                >
                  <UiIcon :src="social_network.logo ?? socialDefault[social_network.app].logo" width="50" height="50" class="fill-neutral-500 inline-block mt-3 mr-2" />
                </UiMaskedLink>
              </template>
            </div>
            <div class="text-xl font-semibold text-left mt-7">
              {{ t('payment') }}
            </div>
            <div class="text-left flex flex-wrap gap-x-2 gap-y-3 mt-3">
              <UiIcon src="/svg/payment-methods/applepay.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
              <UiIcon src="/svg/payment-methods/gpay.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
              <UiIcon src="/svg/payment-methods/paypal.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
              <UiIcon src="/svg/payment-methods/mastercard.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
              <UiIcon src="/svg/payment-methods/visa.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
              <UiIcon src="/svg/payment-methods/ueberweisung.svg" width="54" height="35" class="border border-neutral-200 fill-neutral-500 bg-white inline-block rounded p-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-neutral-50 border-t-2 border-neutral-200">
      <div class="container lg:max-w-screen-xxl mx-auto py-4 px-5 text-center">
        <UiLanguagePlaceholder domain="LayoutFooter" name="use_1" />&nbsp;
        <UiMaskedLink tag="span" role="link" :href="headerFooterLinks?.footer?.agb?.url ?? '/agb'" class="underline">
          <UiLanguagePlaceholder domain="LayoutFooter" name="agb" />
        </UiMaskedLink>&nbsp;
        <UiLanguagePlaceholder domain="LayoutFooter" name="use_2" />&nbsp;
        <UiMaskedLink tag="span" role="link" :href="headerFooterLinks?.footer?.datenschutz?.url ?? '/datenschutz'" class="underline">
          <UiLanguagePlaceholder domain="LayoutFooter" name="privacy_policy" />
        </UiMaskedLink>&nbsp;
        <UiLanguagePlaceholder domain="LayoutFooter" name="use_3" />
      </div>
    </div>
  </footer>
</template>

<script setup>
const t = useTranslations('LayoutFooter').translate
const { links: headerFooterLinks } = usePortalData()

const socialDefault = {
  Pinterest: {
    title: 'Pinterest',
    logo: '/svg/icons/social-media/pinterest.svg',
    url: 'https://www.pinterest.de/travanto/'
  },
  Instagram: {
    title: 'Instagram',
    logo: '/svg/icons/social-media/instagram.svg',
    url: 'https://www.instagram.com/travanto.de/'
  },
  Facebook: {
    title: 'Facebook',
    logo: '/svg/icons/social-media/facebook.svg',
    url: 'https://www.facebook.com/Travanto'
  }
}
</script>

<style scoped>
</style>
